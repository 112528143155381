export const useStoreTestimonials = defineStore('StoreTestimonials', {
    state: () => ({
        testimonials: [
            {
                testimonialName: 'Diana Valeria',
                testimonialContent: 'When my grandchildren ask me about what I did during the pandemic, I\'m going ' +
                        'to tell them about how I got stuck at my parents\' house learning something I always wanted... music. I can\'t thank Andy enough!',
                testimonialImage: 'https://d1ma7tzlswibx6.cloudfront.net/images-general/testimonials-diana-valeria.jpg',
            },
            {
                testimonialName: 'Alex Duell',
                testimonialContent: 'I first picked up the guitar just over 6 months ago and have been learning ' +
                        'completely through Andy\'s videos on his app. It\'s great to have so many practice songs with ' +
                        'the chords and strumming patterns broken down.',
                testimonialImage: 'https://d1ma7tzlswibx6.cloudfront.net/images-general/testimonials-alex-duell.jpg',
            },
            {
                testimonialName: 'Sarah Patchett',
                testimonialContent: 'I could never have dreamed that in a short space of time I would actually be ' +
                        'playing along with some of my favourite songs, but I am and I’m loving it, It\'s so much easier to learn chords while having fun learning songs, I\'m hooked! Thanks Andy',
                testimonialImage: 'https://d1ma7tzlswibx6.cloudfront.net/images-general/testimonials-sarah-patchett.jpg',
            },
            {
                testimonialName: 'John Blencowe',
                testimonialContent: 'I am loving your courses and practising so much more now, my improvement is apparent already. Well done and thank you again!',
                testimonialImage: 'https://d1ma7tzlswibx6.cloudfront.net/images-general/john-blencowe-testimonial-pic.jpg',
            },
            {
                testimonialName: 'Jo Greenwood',
                testimonialContent: 'Andy’s emphasis on learning to play along to real songs and have fun as quickly as possible taught me to make the most of everything' +
                        ' I learnt and gave me the confidence to play in public and even to start writing and recording my own songs!',
                testimonialImage: 'https://d1ma7tzlswibx6.cloudfront.net/images-general/testimonials-jo-greenwood.jpg',
            },
            {
                testimonialName: 'Anuradha Ayyar',
                testimonialContent: 'The website is brilliant! It has allowed me to keep a close tab on my lessons and practice plans. Keep up the excellent work Andy and team!',
                testimonialImage: 'https://d1ma7tzlswibx6.cloudfront.net/images-general/testimonials-anuradha-ayyar.jpg',
            },
            {
                testimonialName: 'Annette Pedersen',
                testimonialContent: 'I can\'t even begin to tell you how much I have enjoyed my lessons with Andy Guitar. I considered myself too old to learn, and you have proven me wrong.',
                testimonialImage: 'https://d1ma7tzlswibx6.cloudfront.net/images-general/testimonials-annette-pederson.jpg',
            },
            {
                testimonialName: 'Peter Howard',
                testimonialContent: 'Really enjoying the way Andy teaches, and my enthusiasm builds everyday as I feel and hear myself getting better all the time.',
                testimonialImage: 'https://d1ma7tzlswibx6.cloudfront.net/images-general/peter-howard-testimonial.jpg',
            },
            {
                testimonialName: 'J.T. Lemon',
                testimonialContent: 'We had a wonderful time learning how to play the ukulele with Andy! My 11-year old son has ' +
                        'gone from beginner to awesome in a short amount of time and I have been learning to play as well.',
                testimonialImage: 'https://d1ma7tzlswibx6.cloudfront.net/images-general/testimonials-jt-lemon.jpg',
            },
        ],
    }),
});
